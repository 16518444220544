<template>
	<v-container fluid fill-height class="align-content-start">
		<TenantDashboard v-if="!isAdmin" />
	</v-container>
</template>

<script>

import useUser from "@/composables/useUser";
import TenantDashboard from "@/views/TenantDashboard.vue"

export default {
	name: 'Home',
	components: { TenantDashboard },

	setup(_, {root}) {
		const user = useUser();
		if(user.isAdmin.value) {
			root.$router.replace({name:"Admin"});
		}
		return { isAdmin:user.isAdmin };
	}
}
</script>

<style>
.full-height {
	height: 100%;
}

.no-results-image-wrapper {
	height: 38vh;
	width: 38vh;
	border-radius: 50%;
	justify-content: center;
}

.no-results-image-wrapper + h4 {
	margin-top: 8vh;
}
</style>
