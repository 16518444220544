<template>
	<v-row dense :class="{ 'full-height': !loading && !items.length, 'pt-sm-6': items.length }">
		<!-- Loader -->
		<template v-if="loading">
			<v-col cols="12" sm="6" md="4" v-for="i in 3" :key="i">
				<v-skeleton-loader type="article" class="py-1"/>
			</v-col>
		</template>

		<!-- No Results -->
		<template v-else-if="items.length <= 0">
			<div class="d-flex flex-column flex-grow-1 align-center justify-sm-center" :class="{ 'mt-16': $vuetify.breakpoint.xs }">
				<div class="d-flex no-results-image-wrapper">
					<v-img contain :src="require('@/assets/NoSearchResult.svg')"/>
				</div>
				<h4 align="center" class="text-h4 text--primary">No results found</h4>
				<p class="mt-5 mx-4 text-body-1 text--secondary">Your account may still need to be configured by your administrator</p>
			</div>
		</template>

		<!-- Card -->
		<template v-else>
			<v-col cols="12">
				<v-subheader class="text-subtitle-1 font-weight-bold">Active Schedule Setpoints</v-subheader>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" v-for="(item, i) in items" :key="i">
				<v-card v-ripple :to="item.link">
					<v-card-title class="text-h5 mb-2"> {{ item.title }} </v-card-title>

					<v-card-text>
						<v-row align="center" class="setpoint">
							<v-col cols="12">
								<v-fade-transition mode="out-in">
									<span
										class="text-h2"
										:key="i+'-setpoint'"
									>{{ item.events.active.event.setpoint }}</span>
								</v-fade-transition>
								<span class="text-h5">&deg;F</span>
							</v-col>
						</v-row>
						<v-row class="mt-n2 next-event">
							<v-col cols="12" class="text-body-2">
								<v-fade-transition mode="out-in">
									<span v-if="item.events.next" :key="i+'-time'">
										Until {{ item.events.next.event.start | fromMilitaryTime }} on {{ item.events.next.day.name }}
									</span>
									<span v-else>
										Using default schedule output
									</span>
								</v-fade-transition>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</template>

	</v-row>
</template>

<script>
import { reactive, toRefs, watchEffect, onUnmounted } from "@vue/composition-api";
import { useSchedule } from "@/composables/schedule";
import map from "lodash/fp/map";

const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export default {
	name: "TenantDashboard",
	components: { },

	setup() {
		const { schedules, loading } = useSchedule();
		const state = reactive({
			loading,
			items: [],
			time: new Date(),
		});

		const computeEventItems = (schedule, date) => {
			const dayIndex = date.getDay();
			const day = schedule.getDay(days[dayIndex]);

			const hours = date.getHours(), minutes = date.getMinutes();

			const activeEvent = day.getActiveEventAt(hours, minutes);

			const events = { active:null, next:null };

			if(activeEvent != null) {
				const nextEvent = day.getNextEventAfter(activeEvent.event);
				events.active = activeEvent;
				events.next = nextEvent;
			}else{
				events.active = { event: { setpoint: schedule.defaultOutput } };
			}
			const link = `/schedules/${schedule.id}`;

			return { title: schedule.name, link, events };
		};

		const updateItems = (date) => {
			date = date || new Date();
			state.items = map(
				schedule => computeEventItems(schedule, date),
				schedules.value
			);
		};

		// Triggers when state.time is updated
		watchEffect(() => updateItems(state.time));

		const updateTimeOnMinuteChanged = () => {
			const now = new Date();
			const minutesChanged = state.time.getMinutes() !== now.getMinutes();
			if(minutesChanged) state.time = now;
		};
		const timer = setInterval(updateTimeOnMinuteChanged, 1000);
		onUnmounted(() => clearInterval(timer));

		return toRefs(state);
	}
}
</script>

<style>
.full-height {
	height: 100%;
}

.no-results-image-wrapper {
	height: 38vh;
	width: 38vh;
	border-radius: 50%;
	background-color: white;
}

.no-results-image-wrapper + h4 {
	margin-top: 8vh;
}
</style>
